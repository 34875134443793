import { format, formatDistanceToNowStrict, differenceInDays, parseISO } from 'date-fns'

import i18next from 'i18next'

export enum DATE_FORMAT {
  DD_MM_YYYY = 'dd/MM/yyyy',
  D_LLL = 'd LLL',
  DD_LLL_YYYY = 'dd LLL yyyy',
}

export enum DATE_LOCALE_FORMAT {
  'zh-cn' = 'yyyy/MM/dd',
  'en-US' = 'MM/dd/yyyy',
  'de' = 'dd.MM.yyyy',
  'en-gb' = 'dd/MM/yyyy',
  'it' = 'dd/MM/yy',
}

const TIME_FORMAT = 'h:mmaaa'

const SHOW_DATE_DISTANCE_THRESHOLD_DAYS = 10

export const formatDate = (dateString?: string, dateLocale?: string, isRelative: boolean = false) => {
  if (!dateString) return

  const date = parseISO(dateString)
  const daysDiff = differenceInDays(date, Date.now())

  if (isRelative && daysDiff < 0 && Math.abs(daysDiff) < SHOW_DATE_DISTANCE_THRESHOLD_DAYS) {
    return `${formatDistanceToNowStrict(date)} ${i18next.t('project.list.label_date_ago')}`
  }

  return format(date, DATE_LOCALE_FORMAT[dateLocale as keyof typeof DATE_LOCALE_FORMAT] ?? DATE_LOCALE_FORMAT['en-US'])
}

export const formatDateByTime = (dateString?: string, dateLocale?: string) => {
  if (!dateString) return
  const date = parseISO(dateString)

  //add time
  return format(
    date,
    DATE_LOCALE_FORMAT[dateLocale as keyof typeof DATE_LOCALE_FORMAT] + ` ${TIME_FORMAT}` ??
      DATE_LOCALE_FORMAT['en-US'] + ` ${TIME_FORMAT}`,
  )
}
